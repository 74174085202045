<script>
import api from '@/command/api'
import CardIconLabel from '../../../components/CardIconLabel'
import Naves from '@/views/com/Naves'
export default {
  name: 'labelManagement',
  data() {
    return {
      ...api.command.getState(),
      // 标签管理数据
      tagData: [],
      activeKey: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      api.freight.getTypeList.call(this, { url: '/base/farmLabelType/queryList' })
    },
    sendData({ data, setHidden }, item) {
      api.freight.addType
        .call(this, {
          url: '/base/farmLabelData',
          params: {
            ...item,
            ...data,
            labelTypeId: item.id,
          },
        })
        .then(setHidden)
    },
    onEdit({ data, setHidden }, item) {
      api.freight.editType
        .call(this, {
          url: '/base/farmLabelData',
          params: {
            ...item,
            ...data,
            labelTypeId: item.id,
          },
        })
        .then(setHidden)
    },
    onDeleteData(data) {
      api.freight.deleteType.call(this, {
        url: `/base/farmLabelData/${data.id}`,
      })
    },
    renderTagData() {
      /* let tagList = []
      if (this.activeKey === 0) {
        tagList = ['food_drive_label']
      } else if (this.activeKey === 1) {
        tagList = ['farm_experience_pick', 'farming_experience_fish']
      } else if (this.activeKey === 2) {
        tagList = ['farmhouse_public', 'farmhouse_room', 'farmhouse_food_safety', 'farmhouse_bathroom']
      } else if (this.activeKey === 3) {
        tagList = []
      } else if (this.activeKey === 4) {
        tagList = ['fram_adopt_legal', 'fram_adopt_grow', 'fram_adopt_other', 'fram_adopt_user_label']
      } else if (this.activeKey === 5) {
        tagList = ['other_label', 'scenic_label']
      }
      const filteredData = this.tagData.reduce((acc, item) => {
        if (tagList.includes(item.labelTypeCode)) {
          acc.push(item)
        }
        return acc
      }, []) */
      const filteredData = this.tagData[this.activeKey].farmLabelTypeVOList || []
      return (
        <div>
          {filteredData.map((item) => {
            const code = [
              'farmhouse_bathroom',
              'farmhouse_food_safety',
              'farmhouse_room',
              'farmhouse_public',
              'fram_adopt_legal',
              'fram_adopt_grow',
              'fram_adopt_other',
            ]
            return (
              <CardIconLabel
                data={null}
                styles={{ marginBottom: '10px' }}
                title={item.labelTypeName}
                icons={item.farmLabelDataVOS.map((e) => ({
                  ...e,
                  name: e.labelName,
                }))}
                showIcon={code.indexOf(item.labelTypeCode) !== -1}
                //   showIcon={item.iconState == '1' ? true : false}
                onSuccess={(data) => this.sendData(data, item)}
                onEdit={(data) => this.onEdit(data, item)}
                onDelete={this.onDeleteData}
                labelTypeCode={item.labelTypeCode}
              />
            )
          })}
        </div>
      )
    },
  },
  render() {
    // ['农家乐', '农事体验', '酒店民宿', '景点门票', '云认养', '主体信息']
    return (
      <div class="card-box">
        <Naves
          navData={this.tagData.map((e) => e.labelTypeName)}
          onActive={(index) => (this.activeKey = index)}
          activeCurrentKey={this.activeKey}
        />
        {this.renderTagData()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.card-box {
  height: calc(100vh - 108px);
  overflow-y: auto;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
